import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";

import styles from "@components/v1/typography/ButtonLink.module.css";
import linkStyles from "@components/v1/typography/InternalLink.module.css";
import IconBase from "@components/v2/icons/IconBase";
import chevronRight from "@icons/solid/chevron-right.svg";
import { Colors } from "@utils/colorUtils";

// this component is used exclusively as an alternative to InternalLink when you want to use only an
// onClick handler. so, onClick is required for this component and href is not allowed (use
// InternalLink directly instead). buttons are also automatically in the tabindex, so it isn't
// needed as a prop.
type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
type Props = Omit<ButtonProps, "href" | "tabIndex"> &
  Required<Pick<ButtonProps, "onClick">> & {
    arrow?: boolean;
    color?: Extract<Colors, "gray" | "white">;
    expand?: boolean;
    stylized?: boolean;
  };

const ButtonLink = ({
  arrow = false,
  children,
  className,
  color,
  expand = false,
  stylized = true,
  ...otherButtonProps
}: Props) => {
  const classNames = ["component-ButtonLink", linkStyles.reset, styles.button, arrow && linkStyles.withArrow];
  if (expand && !arrow) classNames.push(linkStyles.expand);
  if (stylized && !arrow) classNames.push(linkStyles.stylized);
  if (className) classNames.push(className);
  if (color) classNames.push(linkStyles[color]);

  return (
    // eslint-disable-next-line react/forbid-elements
    <button className={classNames.join(" ")} {...otherButtonProps}>
      <span className={linkStyles.text}>{children}</span>
      {arrow && (
        <span className={linkStyles.arrow}>
          <IconBase icon={chevronRight} />
        </span>
      )}
    </button>
  );
};

export default ButtonLink;
