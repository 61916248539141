import { IonPage } from "@ionic/react";
import classnames from "classnames";
import { useCallback, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useLocation } from "react-router";

import styles from "@components/v1/ActivatePage.module.css";
import CardBase from "@components/v1/cards/CardBase";
import Button from "@components/v2/buttons/Button";
import useMutationWithValidation from "@hooks/useMutationWithValidation";
import logoVertical from "@images/logo-vertical.svg";
import {
  CoworkerReferralCancelDocument,
  CoworkerReferralCancelMutation,
  CoworkerReferralCancelMutationVariables
} from "@typing/Generated";

const NotInterestedPage = () => {
  const intl = useIntl();
  const location = useLocation();

  const [cancelReferral] = useMutationWithValidation<
    CoworkerReferralCancelMutation,
    CoworkerReferralCancelMutationVariables
  >(CoworkerReferralCancelDocument, "coworkerReferral");

  const handleCancelReferral = useCallback(
    (id: string) => {
      cancelReferral({ id });
    },
    [cancelReferral]
  );

  const handleClosePage = () => {
    window.close();
  };

  useEffect(() => {
    if (location.search.includes("coworker-referral-id")) {
      const searchParams = new URLSearchParams(location.search);
      const coworkerReferralId = searchParams.get("coworker-referral-id");
      if (coworkerReferralId) {
        handleCancelReferral(coworkerReferralId);
      }
    }
  }, [handleCancelReferral, location.search]);

  return (
    <IonPage className={classnames(styles.page, styles.notInterestedPage, "flex", "items-center", "justify-center")}>
      <CardBase
        body={
          <div className={classnames("flex", "flex-column", "align-center", "items-center", "padding-40")}>
            <div className="margin-40-bottom">
              <img alt={intl.formatMessage({ id: "dictionary.grayce" })} src={logoVertical} />
            </div>
            <div className="margin-20-bottom">
              <FormattedMessage id="pages.notInterested.informationRemoved" />
            </div>
            <div className="margin-40-bottom">
              <FormattedMessage id="pages.notInterested.weAreHere" />
            </div>
            <div>
              <Button onClick={handleClosePage}>
                <FormattedMessage id="dictionary.closeThisPage" />
              </Button>
            </div>
          </div>
        }
        rounding="none"
      />
    </IonPage>
  );
};

export default NotInterestedPage;
