import { ApolloLink } from "@apollo/client";

import operationVariableTypes from "@generated/operationVariableTypes";

const convertVariables = (operationName: string, variables: Record<string, any>): Record<string, any> => {
  const convertedVariables: Record<string, any> = {};

  for (const variableName in variables) {
    const variableType = operationVariableTypes[operationName][variableName];

    const value = variables[variableName];
    if (value === undefined || value === null) {
      convertedVariables[variableName] = value;
    } else if (variableType === "Int!" || variableType === "Int") {
      convertedVariables[variableName] = parseInt(value, 10);
    } else if (variableType === "Float!" || variableType === "Float") {
      convertedVariables[variableName] = parseFloat(value);
    } else {
      convertedVariables[variableName] = value; // Leave other types untouched
    }
  }

  return convertedVariables;
};

const TypeConversionLink = new ApolloLink((operation, forward) => {
  // Traverse and convert variables
  if (operation.variables) {
    operation.variables = convertVariables(operation.operationName, operation.variables);
  }

  return forward(operation);
});

export default TypeConversionLink;
