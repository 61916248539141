import { useIonToast } from "@ionic/react";
import { useCallback, useContext, useEffect } from "react";
import { IIdleTimer, useIdleTimer } from "react-idle-timer";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";

import { sessionContext } from "@context/Contexts";
import useMyAdmin from "@hooks/useMyAdmin";
import useMyExpert from "@hooks/useMyExpert";
import xmark from "@icons/solid/xmark.svg";
import { oneHourInMs, oneMinuteInMs } from "@typing/Constants";

const IdleHandler = () => {
  const { sessionState } = useContext(sessionContext);
  const [present, dismiss] = useIonToast();
  const intl = useIntl();
  const history = useHistory();
  const myAdmin = useMyAdmin();
  const myExpert = useMyExpert();

  const onIdle = useCallback(() => {
    if (sessionState.authToken) {
      dismiss();
      history.push("/sign-out");
    }
  }, [dismiss, history, sessionState.authToken]);

  const onPrompt = useCallback(() => {
    if (sessionState.authToken) {
      present({
        buttons: [{ handler: () => dismiss(), icon: xmark }],
        color: "danger",
        cssClass: "toast",
        duration: 60_000,
        message: intl.formatMessage({ id: "models.session.idlePrompt" }),
        position: "top"
      });
    }
  }, [dismiss, intl, present, sessionState.authToken]);

  const onAction = useCallback(
    (_event?: Event, idleTimer?: IIdleTimer) => {
      if (idleTimer) {
        idleTimer.reset();
      }
      dismiss();
    },
    [dismiss]
  );

  const idleTimer = useIdleTimer({
    crossTab: true,
    disabled: !!import.meta.env.VITE_IDLE_TIMEOUT_DISABLED,
    leaderElection: true,
    onAction,
    onIdle,
    onPrompt,
    promptBeforeIdle: oneMinuteInMs,
    startManually: false,
    syncTimers: 200,
    timeout: oneHourInMs
  });

  useEffect(() => {
    if (!myAdmin && !myExpert) {
      idleTimer.pause();
    } else {
      idleTimer.resume();
    }
  }, [idleTimer, myAdmin, myExpert]);

  return null;
};

export default IdleHandler;
