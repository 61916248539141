import { IonIcon } from "@ionic/react";
import { ComponentProps } from "react";

import styles from "@components/v2/icons/IconBase.module.css";

type Props = Omit<ComponentProps<typeof IonIcon>, "icon" | "src"> & {
  icon: IconSvg;
  spin?: boolean;
};

const IconBase = ({ className, icon, spin = false, ...props }: Props) => {
  const classNames = [];
  if (className) classNames.push(className);
  if (spin) classNames.push(styles.spin);

  return <IonIcon {...props} className={classNames.join(" ")} src={icon} />;
};

export default IconBase;
