const dictionary = {
  actions: "Actions",
  active: "Active",
  activity: "Activity",
  actor: "Actor",
  actual: "Actual",
  add: "Add",
  addNote: "Add note",
  addSomethingElse: "I need help with something else...",
  added: "Added",
  admin: "Admin",
  adminSignIn: "Admin sign in",
  administrative: "Administrative",
  admins: "Admins",
  after: "After",
  all: "All",
  and: "and",
  anonymousUser: "Anonymous user",
  any: "Any",
  applications: "Applications",
  approve: "Approve",
  archive: "Archive",
  archived: "Archived",
  article: "Article",
  articles: "Articles",
  asia: "Asia",
  attachments: "Attachments",
  back: "Back",
  balance: "Balance:",
  before: "Before",
  benefits: "Benefits",
  blank: "blank",
  button: "Button",
  cancel: "Cancel",
  carePlan: "Care Plan",
  carePlanning: "Care Plan package",
  change: "change",
  chat: "Chat",
  clear: "Clear",
  clickHere: "Click here",
  client: "Member",
  clients: "Members",
  close: "Close",
  closeThisPage: "Close this page",
  comments: "Comments",
  community: "Community",
  compHours: "Comp hours",
  complete: "Complete",
  confirm: "Confirm",
  content: "Content",
  contracts: "Contracts",
  cookie: "Cookie",
  copied: "Copied!",
  copy: "Copy",
  cost: "Cost",
  count: "({count})",
  created: "Created",
  data: "Data",
  date: "Date",
  delete: "Delete",
  details: "details",
  discard: "Discard",
  domain: "Domain",
  done: "Done",
  download: "Download",
  draft: "Draft Mode",
  draftKey: "Save",
  edit: "Edit",
  editOidcProvider: "Edit SSO provider",
  eligibilities: "Eligibilities",
  eligibility: "Eligibility",
  eligibilityFiles: "Eligibility files",
  eligibilityList: "Eligibility list",
  emailMessages: "Email messages",
  emails: "Emails",
  employees: "Employees",
  employers: "Employers",
  emptyArray: "[]",
  emptyString: '""',
  enterPhoneNumber: "Enter phone number",
  europe: "Europe",
  expand: "Expand",
  expertAssignment: "Care Partner assignment",
  experts: "Care Partners",
  expiring: "Expiring",
  facebook: "Facebook",
  fromGrayce: "From Grayce",
  goals: "Goals",
  grayce: "Grayce",
  groups: "Groups",
  hide: "Hide",
  hideTranscript: "Hide transcript",
  hotline: "Hotline",
  hourLower: "{count, plural, one {hour} other {hours}}",
  hours: "Hours",
  hoursLower: "hours",
  howItWorks: "How it works",
  impersonate: "Impersonate",
  inReview: "In review",
  internalNoteTemplates: "Internal note templates",
  join: "Join",
  journey: "Journey",
  journeys: "Journeys",
  justNow: "Just now",
  library: "Library",
  linkedIn: "LinkedIn",
  loadMore: "Load more",
  loading: "Loading",
  logIn: "Log in",
  me: "Me",
  menu: "Menu",
  messages: "Messages",
  minuteLower: "{count, plural, one {minute} other {minutes}}",
  month: "Month",
  more: "More",
  myStories: "MyStories",
  new: "New",
  newArticle: "New article",
  newBenefit: "New benefit",
  newContract: "New contract",
  newEmployee: "New employee",
  newEmployer: "New employer",
  newExpert: "New Care Partner",
  newGoal: "New goal",
  newInternalNoteTemplate: "New internal note template",
  newMyStory: "New MyStory",
  newOidcProvider: "New SSO provider",
  newWebinar: "New live event",
  next: "Next",
  no: "No",
  noDocumentsFound: "No documents found",
  noInternalNotesFound: "No internal notes found",
  noTimeLogsFound: "No time logs found",
  none: "None",
  notApplicable: "N/A",
  notes: "Notes",
  off: "Off",
  oidcProviders: "SSO providers",
  ok: "OK",
  on: "On",
  open: "Open",
  or: "or",
  other: "Other",
  outOfOffice: "Out of office",
  packageGrants: "Package grants",
  packageLower: "package",
  peerSupportRequests: "Peer support",
  pin: "Pin",
  pinned: "Pinned",
  posts: "Posts",
  preview: "Preview",
  previous: "Previous",
  publish: "Publish",
  publishAll: "Publish all",
  publishChanges: "Publish changes",
  publishKey: "Publish",
  published: "Published",
  punctuation: {
    asterisk: "*",
    at: "@",
    bullet: "•",
    circle: "●",
    closeParen: ")",
    colon: ":",
    comma: ",",
    dash: "-",
    mdash: "—",
    openParen: "(",
    percent: "%",
    period: ".",
    plus: "+",
    quoteLeft: "“",
    quoteRight: "”",
    rightArrow: "→",
    slash: "/",
    verticalBar: "|"
  },
  purchase: "Purchase",
  purchaseHours: "Purchase hours",
  reactions: "Reactions",
  reallyDelete: "Really delete?",
  reassign: "Reassign",
  refreshPeriods: "Refresh periods",
  reject: "Reject",
  remainingLower: "remaining",
  remove: "Remove",
  rename: "Rename",
  resend: "Resend",
  return: "Return",
  save: "Save",
  saveChanges: "Save changes",
  saveDraft: "Save draft",
  saved: "Saved",
  scanning: "Scanning attachments",
  scanningImage: "Scanning image",
  schedule: "Schedule",
  scheduled: "Scheduled",
  search: "Search...",
  selectBestMatch: "Select the best match",
  selectOne: "Select one of the following",
  selectTimezone: "Select a timezone",
  self: "Self",
  selfPaid: "Self paid",
  send: "Send",
  show: "Show",
  signIn: "Sign in",
  signInWithApple: "Sign in with Apple",
  signInWithGoogle: "Sign in with Google",
  signOut: "Sign out",
  signUp: "Sign up",
  signUpWithApple: "Sign up with Apple",
  signUpWithGoogle: "Sign up with Google",
  skip: "Skip",
  startTypingForOptions: "Start typing for options",
  status: "Status",
  submit: "Submit",
  support: "Support",
  tags: "Tags",
  template: "Template",
  this: "this",
  time: "Time",
  timeAgo: "{time} ago",
  timeLogs: "Time logs",
  timesheets: "Timesheets",
  to: "to",
  today: "Today",
  total: "Total",
  triggers: "Triggers",
  twitter: "Twitter",
  typeHere: "Type here...",
  unarchive: "Unarchive",
  unknown: "Unknown",
  unlimited: "Unlimited",
  upcoming: "Upcoming",
  update: "Update",
  updateNote: "Update note",
  upload: "Upload",
  users: "Users",
  vendor: "Vendor",
  video: "Video",
  viewTranscript: "View transcript",
  webinars: "Live events",
  year: "Year",
  yes: "Yes",
  yesterday: "Yesterday",
  you: "you"
};

export default dictionary;
