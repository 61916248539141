import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { RetryLink } from "@apollo/client/link/retry";
import { useIonToast } from "@ionic/react";
import { createConsumer } from "@rails/actioncable";
import { sha256 } from "crypto-hash";
import { ComponentProps, useCallback, useContext, useMemo } from "react";

import { clearAuthTokens, setOtpVerified } from "@actions/sessionActions";
import { sessionContext } from "@context/Contexts";
import fieldPolicies from "@generated/fieldPolicies";
import relayPagination from "@generated/relayPagination";
import xmark from "@icons/solid/xmark.svg";
import ActionCableLink from "@utils/ActionCableLink";
import TypeConversionLink from "@utils/TypeConversionLink";

type Props = Omit<ComponentProps<typeof ApolloProvider>, "client">;

const ApiProvider = ({ children, ...apolloProps }: Props) => {
  const { dispatch: sessionDispatch, sessionState } = useContext(sessionContext);

  const getActiveToken = useCallback(
    (overrideToken?: string | null) => {
      const impersonatingExpertAuthToken = sessionState.impersonateExpertAuthToken;
      const impersonatingClientAuthToken = sessionState.impersonateClientAuthToken;
      const authToken = sessionState.authToken;

      return overrideToken ?? impersonatingClientAuthToken ?? impersonatingExpertAuthToken ?? authToken;
    },
    [sessionState.authToken, sessionState.impersonateClientAuthToken, sessionState.impersonateExpertAuthToken]
  );

  const mergeFieldMap = useMemo<Record<string, string[]>>(
    () => ({
      ConsultAvailabilityDay: ["availabilities"],
      ExpertSummary: ["needsResponseMessages", "unreadMessages"],
      Goal: ["goalItems"],
      LegalDocument: ["fileResources"],
      Task: ["resourceFeatures"]
    }),
    []
  );

  const mergedFieldPolicies = useMemo(() => {
    const result = { ...fieldPolicies };

    Object.keys(mergeFieldMap).forEach((mergeType: string) => {
      if (Object.keys(result).includes(mergeType)) {
        mergeFieldMap[mergeType].forEach(mergeField => {
          if (result[mergeType].fields) {
            result[mergeType].fields[mergeField] = { merge: (_existing = [], incoming: any[]) => incoming };
          }
        });
      }
    });

    return result;
  }, [mergeFieldMap]);

  const cache = useMemo(
    () =>
      new InMemoryCache({
        typePolicies: {
          ...mergedFieldPolicies,
          Query: {
            fields: {
              ...mergedFieldPolicies.Query.fields,
              ...relayPagination,
              expertSummary: {
                merge: (_existing = {}, incoming: any) => incoming
              },
              pins: {
                merge: (_existing = [], incoming: any[]) => incoming
              }
            }
          }
        }
      }),
    [mergedFieldPolicies]
  );

  const httpLink = useMemo(() => new HttpLink({ uri: import.meta.env.VITE_API_HOST }), []);

  const authLink = useMemo(
    () =>
      setContext((_, { authToken: overrideToken, headers }) => {
        const stringOverrideToken = overrideToken as string | null;
        const token = getActiveToken(stringOverrideToken);

        return {
          headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : ""
          }
        };
      }),
    [getActiveToken]
  );

  const cableUrl = useMemo(() => {
    const url = import.meta.env.VITE_ACTIONCABLE_URL;
    if (url) {
      const token = getActiveToken();
      return `${url}?token=${token ?? ""}`;
    }
  }, [getActiveToken]);

  const typeConversionLink = useMemo(() => ApolloLink.from([TypeConversionLink]), []);

  const splitLink = useMemo(() => {
    const cable = createConsumer(cableUrl ?? "");
    return ApolloLink.split(
      op =>
        op.query.definitions.some(
          definition => definition.kind === "OperationDefinition" && definition.operation === "subscription"
        ),
      // https://github.com/rmosolgo/graphql-ruby/issues/3236

      typeConversionLink.concat(new ActionCableLink({ cable })),
      authLink.concat(typeConversionLink.concat(httpLink))
    );
  }, [authLink, cableUrl, httpLink, typeConversionLink]);

  const [present, dismiss] = useIonToast();

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ locations, message, path }) => {
            // eslint-disable-next-line no-console
            console.log("[GraphQL error]: ", message, JSON.stringify(locations), path);
            if (message === "invalid_jwt") {
              sessionDispatch(clearAuthTokens());
            }
            // If you get an unauthorized and you have no tokens it probably means that you were logged out.
            if (message === "unauthorized") {
              if (!getActiveToken()) {
                sessionDispatch(clearAuthTokens());
              }
              sessionStorage.removeItem("postLoginRedirectTo");
            }
            if (message === "2fa_required") {
              sessionDispatch(setOtpVerified(false));
            }
          });
        }

        if (networkError) {
          // eslint-disable-next-line no-console
          console.log("[Network error]:", networkError);
          present({
            buttons: [{ handler: () => dismiss(), icon: xmark }],
            color: "danger",
            cssClass: "toast",
            duration: 30000,
            message: "Grayce can't connect to the internet. Check your connection and try again.",
            position: "top"
          });
        }
      }),
    [dismiss, getActiveToken, present, sessionDispatch]
  );

  const retryLink = useMemo(
    () =>
      new RetryLink({
        attempts: { max: 5 },
        delay: {
          initial: 150,
          jitter: true,
          max: 1000
        }
      }),
    []
  );

  const persistedQueriesLink = useMemo(() => createPersistedQueryLink({ sha256 }), []);

  const apolloClient = useMemo(
    () =>
      new ApolloClient({
        cache,
        link: errorLink.concat(retryLink.concat(persistedQueriesLink.concat(splitLink)))
      }),
    [cache, errorLink, persistedQueriesLink, retryLink, splitLink]
  );

  return (
    <ApolloProvider {...apolloProps} client={apolloClient}>
      {children}
    </ApolloProvider>
  );
};

export default ApiProvider;
