export const parseDateScalar = (raw: unknown): Date | Date[] | null => {
  if (!raw) {
    return null;
  }

  if (raw instanceof Date) {
    return raw;
  }

  if (raw.constructor.name == "Array") {
    const arrayRaw = raw as unknown[];
    return arrayRaw.map((value: unknown) => parseDateScalar(value) as Date);
  }

  if (typeof raw === "string" || raw instanceof String) {
    const rawString = raw as string;

    const dateMatch = /^(\d\d\d\d)-(\d+)-(\d+)$/.exec(rawString);
    if (dateMatch) {
      const year = parseInt(dateMatch[1]);
      const month = parseInt(dateMatch[2]) - 1;
      const date = parseInt(dateMatch[3]);

      const result = new Date(year, month, date);
      return result;
    }

    const timestampMatch = /^(\d\d\d\d)-(\d+)-(\d+)T(\d+):(\d+):(\d+\.?\d+?)Z$/.exec(rawString);

    if (timestampMatch) {
      const year = parseInt(timestampMatch[1]);
      const month = parseInt(timestampMatch[2]) - 1;
      const date = parseInt(timestampMatch[3]);

      const hour = parseInt(timestampMatch[4]);
      const minute = parseInt(timestampMatch[5]);
      const second = parseFloat(timestampMatch[6]);

      const result = new Date(Date.UTC(year, month, date, hour, minute, second));
      return result;
    }

    // There appears to be no way to create a JS date with an arbitrary timezone, but it can
    // parse properly ISO8601-formatted timestamps that you pass in with a timezone, which
    // is exhausting.
    const nonUtcTimestampMatch = /^(\d\d\d\d)-(\d+)-(\d+)T(\d+):(\d+):(\d+)[+-](\d+):(\d+)$/.exec(rawString);

    if (nonUtcTimestampMatch) {
      return new Date(rawString);
    }
  }

  throw new Error("invalid timestamp to parse");
};
