import { IonCol, IonContent, IonGrid, IonPage, IonRow } from "@ionic/react";
import { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { FormattedMessage, useIntl } from "react-intl";

import styles from "@components/v1/InfoPagesStyles.module.css";
import ExternalLink from "@components/v1/typography/ExternalLink";
import Text from "@components/v2/typography/Text";
import logoPurple from "@images/logo-purple.svg";
import { privacyPolicyUrl } from "@utils/linkUtils";

type SectionProps = {
  children?: React.ReactNode;
  messageId: string;
  values?: Record<string, React.ReactNode>;
};

const Section = ({ children, messageId, values }: SectionProps) => (
  <div className={styles.content}>
    <div>{children || <FormattedMessage id={messageId} {...{ values: values ?? values }} />}</div>
  </div>
);

const sections = [
  {
    header: "referACoworkerAgreement.section.intro.header",
    subSections: [
      <Section key="programAgreement" messageId="referACoworkerAgreement.section.intro.programAgreement" />,
      <Section key="platformAgreement" messageId="referACoworkerAgreement.section.intro.platformAgreement" />
    ]
  },
  {
    header: "referACoworkerAgreement.section.eligibility.header",
    subSections: [
      <Section key="eligibilityDescription" messageId="referACoworkerAgreement.section.eligibility.description" />,
      <Section key="eligibilityList" messageId="referACoworkerAgreement.section.eligibility.list">
        <ol>
          <li>
            <FormattedMessage id="referACoworkerAgreement.section.eligibility.list.goodStanding" />
          </li>
          <li>
            <FormattedMessage id="referACoworkerAgreement.section.eligibility.list.atLeastEighteen" />
          </li>
        </ol>
      </Section>
    ]
  },
  {
    header: "referACoworkerAgreement.section.qualification.header",
    subSections: [
      <Section key="qualificationDescription" messageId="referACoworkerAgreement.section.qualification.description" />,
      <Section key="qualificationList" messageId="referACoworkerAgreement.section.qualification.list">
        <ul>
          <li>
            <FormattedMessage id="referACoworkerAgreement.section.qualification.list.notRegistered" />
          </li>
          <li>
            <FormattedMessage id="referACoworkerAgreement.section.qualification.list.validatedEmailDomain" />
          </li>
          <li>
            <FormattedMessage id="referACoworkerAgreement.section.qualification.list.usesReferralLink" />
          </li>
          <li>
            <FormattedMessage id="referACoworkerAgreement.section.qualification.list.activationCriteria" />
          </li>
        </ul>
      </Section>,
      <Section key="qualificationConclusion" messageId="referACoworkerAgreement.section.qualification.conclusion" />
    ]
  },
  {
    header: "referACoworkerAgreement.section.privacy.header",
    subSections: [
      <Section
        key="privacyDescription"
        messageId="referACoworkerAgreement.section.privacy.description"
        values={{
          link: (
            <ExternalLink href={privacyPolicyUrl} linkEventCreateVariables={{}}>
              <FormattedMessage id="referACoworkerAgreement.section.privacy.linkText" />
            </ExternalLink>
          )
        }}
      />
    ]
  },
  {
    header: "referACoworkerAgreement.section.intellectualProperty.header",
    subSections: [
      <Section
        key="intellectualPropertyDescription"
        messageId="referACoworkerAgreement.section.intellectualProperty.description"
      />
    ]
  },
  {
    header: "referACoworkerAgreement.section.termination.header",
    subSections: [
      <Section key="terminationDescription" messageId="referACoworkerAgreement.section.termination.description" />
    ]
  },
  {
    header: "referACoworkerAgreement.section.entireAgreement.header",
    subSections: [
      <Section
        key="entireAgreementDescription"
        messageId="referACoworkerAgreement.section.entireAgreement.description"
      />
    ]
  },
  {
    header: "referACoworkerAgreement.section.additionalDefinedTerms.header",
    subSections: [
      <Section key="additionalDefinedTermsList" messageId="referACoworkerAgreement.section.additionalDefinedTerms.list">
        <ul>
          <li>
            <FormattedMessage id="referACoworkerAgreement.section.additionalDefinedTerms.list.memberDefinition" />
          </li>
          <li>
            <FormattedMessage id="referACoworkerAgreement.section.additionalDefinedTerms.list.notice" />
          </li>
        </ul>
      </Section>
    ]
  }
];

const ReferralProgramAgreementPage = () => {
  const intl = useIntl();
  return (
    <IonPage className={styles.page}>
      <Helmet>
        <title>{intl.formatMessage({ id: "pageTitles.referACoworkerAgreementPage" })}</title>
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link crossOrigin="" href="https://fonts.gstatic.com" rel="preconnect" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Mulish&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <IonContent>
        <IonGrid fixed>
          <IonRow>
            <IonCol className={styles.logoColumn}>
              <img
                alt={intl.formatMessage({ id: "accessibility.altTags.purpleLogo" })}
                className={styles.logo}
                src={logoPurple}
              />
            </IonCol>
          </IonRow>
          <h1 className="visually-hidden">
            <FormattedMessage id="pageTitles.referACoworkerAgreementPage" />
          </h1>
          {sections.map(section => (
            <IonRow className={styles.section} key={section.header}>
              <IonCol className={styles.content}>
                <Text color="primary" tag="h2" variant="2xlSerif">
                  <FormattedMessage id={section.header} />
                </Text>
                {section.subSections.map(subsection => (
                  <Fragment key={subsection.key}>{subsection}</Fragment>
                ))}
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ReferralProgramAgreementPage;
