import { IonList } from "@ionic/react";
import { ComponentProps, useRef, useState } from "react";
import { useIntl } from "react-intl";

import DateStamp from "@components/v1/dates/DateStamp";
import DeleteConfirmationAlert from "@components/v1/DeleteConfirmationAlert";
import LegalDocumentForm from "@components/v1/forms/LegalDocumentForm";
import Modal, { ModalElement } from "@components/v1/Modal";
import TrackedItem from "@components/v1/trackers/TrackedItem";
import TrackedItemLine from "@components/v1/trackers/TrackedItemLine";
import Tracker from "@components/v1/trackers/Tracker";
import styles from "@components/v1/trackers/Tracker.module.css";
import TrackerItemModal from "@components/v1/trackers/TrackerItemModal";
import useDestroyLegalDocument from "@hooks/mutations/useDestroyLegalDocument";
import useOpener from "@hooks/useOpener";
import edit from "@icons/solid/pen.svg";
import trash from "@icons/solid/trash.svg";
import upload from "@icons/solid/upload.svg";
import { SortByTimestampDirectionEnum } from "@typing/Enums";
import { GetJourneyWithPlanQuery, LegalDocumentFragment, LegalDocumentKindEnum } from "@typing/Generated";
import { sortByTimestamp } from "@utils/modelUtils";

type Props = {
  journey: GetJourneyWithPlanQuery["journey"];
};

type LegalDocumentProps = {
  handleDestroy: (legalDocument: LegalDocumentFragment) => void;
  handleEdit: (ld: LegalDocumentFragment, uploadOnly: boolean) => void;
  legalDocument: LegalDocumentFragment;
};

const LegalDocument = ({ handleDestroy, handleEdit, legalDocument }: LegalDocumentProps) => {
  const opener = useOpener();
  const deleteOpener = useOpener();
  const intl = useIntl();

  const itemProps: ComponentProps<typeof TrackedItemLine>[] = [];
  if (legalDocument.status) {
    itemProps.push({
      description: intl.formatMessage({ id: `models.legalDocument.statusOptions.${legalDocument.status}` }),
      labelKey: "models.client.legalDocument.status"
    });
  }
  if (legalDocument.storageLocation) {
    itemProps.push({
      description: legalDocument.storageLocation,
      labelKey: "pages.legalDocuments.list.storageLocation"
    });
  }
  if (legalDocument.updateDate) {
    itemProps.push({
      description: <DateStamp value={legalDocument.updateDate} />,
      labelKey: "pages.legalDocuments.list.updateDate"
    });
  }

  return (
    <>
      <TrackedItem
        onClick={opener.open}
        subtitle={intl.formatMessage({ id: `models.legalDocument.statusOptions.${legalDocument.status}` })}
        title={intl.formatMessage({ id: `models.legalDocument.kindOptions.${legalDocument.kind}` })}
      />
      <TrackerItemModal
        actions={[
          {
            handler: () => {
              handleEdit(legalDocument, false);
            },
            icon: edit,
            labelKey: "dictionary.edit"
          },
          {
            handler: () => {
              deleteOpener.open();
            },
            icon: trash,
            labelKey: "dictionary.remove"
          },
          {
            handler: () => {
              handleEdit(legalDocument, true);
            },
            icon: upload,
            labelKey: "dictionary.upload"
          }
        ]}
        fileResources={legalDocument.fileResources}
        notes={legalDocument.notes}
        opener={opener}
        subtitle={intl.formatMessage({ id: `models.legalDocument.statusOptions.${legalDocument.status}` })}
        title={intl.formatMessage({ id: `models.legalDocument.kindOptions.${legalDocument.kind}` })}
      >
        <IonList className="ion-no-padding">
          {itemProps.map((props, index) => (
            // array index key is actually fine here since the array is static and it's the most convenient thing to use
            // eslint-disable-next-line react/no-array-index-key
            <TrackedItemLine key={index} {...props} color={index % 2 === 0 ? "neutral" : "white"} />
          ))}
        </IonList>
      </TrackerItemModal>
      <DeleteConfirmationAlert
        messageKey="pages.client.legalDocuments.reallyDelete"
        messageValue={intl.formatMessage({ id: `models.legalDocument.kindOptions.${legalDocument.kind}` })}
        messageValueKey="legalDocumentKind"
        onDelete={() => {
          handleDestroy(legalDocument);
        }}
        opener={deleteOpener}
      />
    </>
  );
};

const LegalDocuments = ({ journey }: Props) => {
  const intl = useIntl();
  const addOpener = useOpener();
  const legalModalRef = useRef<ModalElement>(null);
  const [uploadOnly, setUploadOnly] = useState(false);
  const [editingLegalDocument, setEditingLegalDocument] = useState<LegalDocumentFragment | undefined>(undefined);

  const handleLegalDocumentError = () => {
    if (legalModalRef.current) {
      legalModalRef.current.scrollToTop();
    }
  };

  const destroyLegalDocument = useDestroyLegalDocument(journey.id);

  return (
    <div className="full-width">
      <Tracker
        handleAdd={() => {
          setEditingLegalDocument(undefined);
          addOpener.open();
        }}
        kind="legal"
      >
        <div className={styles.items}>
          {sortByTimestamp(
            journey.legalDocuments,
            lg => lg.createdAt,
            SortByTimestampDirectionEnum.REVERSE_CHRONOLOGICAL
          ).map(legalDocument => (
            <LegalDocument
              handleDestroy={ld => {
                destroyLegalDocument({ id: ld.id });
              }}
              handleEdit={(ld, uploadOnly) => {
                setEditingLegalDocument(ld);
                setUploadOnly(uploadOnly);
                addOpener.open();
              }}
              key={legalDocument.id}
              legalDocument={legalDocument}
            />
          ))}
        </div>
      </Tracker>
      <Modal
        opener={addOpener}
        ref={legalModalRef}
        title={intl.formatMessage({
          id: editingLegalDocument
            ? "components.CarePlan.sections.trackers.legal.edit"
            : "components.CarePlan.sections.trackers.legal.add"
        })}
      >
        {() => (
          <LegalDocumentForm
            defaultAsComplete
            journeyId={journey.id}
            kindOptions={Object.values(LegalDocumentKindEnum)}
            legalDocument={editingLegalDocument}
            onCancel={addOpener.close}
            onError={handleLegalDocumentError}
            onSuccess={addOpener.close}
            uploadOnly={uploadOnly}
          />
        )}
      </Modal>
    </div>
  );
};

export default LegalDocuments;
