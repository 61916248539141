import * as braze from "@braze/web-sdk";
import { datadogLogs } from "@datadog/browser-logs";
import { ReactNode, useContext, useEffect, useMemo } from "react";
import ReactGA from "react-ga4";

import { cookieConsentContext } from "@context/Contexts";
import useLazyQueryWithPromise from "@hooks/useLazyQueryWithPromise";
import useMe from "@hooks/useMe";
import {
  GetMyBrazeAuthenticationJwtDocument,
  GetMyBrazeAuthenticationJwtQuery,
  GetMyBrazeAuthenticationJwtQueryVariables
} from "@typing/Generated";
import { isNativeApp, isWebApp } from "@utils/platformUtils";
import * as BrazeCordovaPlugin from "braze-cordova-sdk";

type Props = { children: ReactNode };

const AnalyticsProvider = ({ children }: Props) => {
  const me = useMe();
  const gid = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;

  const brazeApiKey = import.meta.env.VITE_BRAZE_API_KEY;
  const brazeBaseUrl = import.meta.env.VITE_BRAZE_SDK_ENDPOINT;

  const cookieConsentStatus = useContext(cookieConsentContext);
  const consentGiven = cookieConsentStatus.acceptsAnalyticsCookies === true;

  const getMyBrazeAuthenticationJwt = useLazyQueryWithPromise<
    GetMyBrazeAuthenticationJwtQuery,
    GetMyBrazeAuthenticationJwtQueryVariables
  >(GetMyBrazeAuthenticationJwtDocument);

  //////////////////////////////////////////////////////////////////////////////////////
  // BRAZE
  //////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (brazeApiKey && brazeBaseUrl && me) {
      if (isWebApp()) {
        braze.initialize(brazeApiKey, {
          baseUrl: brazeBaseUrl,
          enableSdkAuthentication: true,
          noCookies: consentGiven === false
        });
      }

      getMyBrazeAuthenticationJwt({ fetchPolicy: "network-only" }).then(response => {
        const jwt = response.data.me?.brazeAuthenticationJwt;
        if (jwt) {
          if (isNativeApp()) {
            BrazeCordovaPlugin.default.enableSdk();
            BrazeCordovaPlugin.default.changeUser(me.id, jwt);
            BrazeCordovaPlugin.default.setFirstName(me.firstName);
            BrazeCordovaPlugin.default.setLastName(me.lastName);
          } else if (isWebApp()) {
            braze.changeUser(me.id, jwt);
            braze.openSession();
            braze.getUser()?.setFirstName(me.firstName);
            braze.getUser()?.setLastName(me.lastName);
          }
        }
      });
    }
  }, [brazeApiKey, brazeBaseUrl, consentGiven, getMyBrazeAuthenticationJwt, me]);

  // The cookie consent for analytics cookies controls the services in here. If its value is null
  // it means that we don't have consent yet and therefore can't load the cookies. If it is true,
  // we should go ahead, and if it is false then we have to remove any tracking cookies that have
  // already been set and shut down the services.
  //
  // So, each of the services initialized in here needs to:
  //   * initialize itself, only if the cookie consent is affirmative
  //   * send identifying data about the user if there is one and we want to track them,
  //   * and remove cookies if consent is revoked. We don't remove the cookies directly, but
  //     rather use the APIs for the services to indicate the status of consent.

  //////////////////////////////////////////////////////////////////////////////////////
  // GA
  // GA can work without cookies in a more limited capacity, so we want to initialize
  // it regardless. But we can default the storage to denied and then turn it on when
  // the user gives consent.
  //////////////////////////////////////////////////////////////////////////////////////

  useMemo(() => {
    if (gid) {
      ReactGA.initialize(gid);
      // Tun this on for local testing
      // ReactGA.gtag("set", "debug_mode", true);

      ReactGA.gtag("consent", "default", {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ad_storage: "denied",
        // eslint-disable-next-line @typescript-eslint/naming-convention
        analytics_storage: "denied"
      });
    }
  }, [gid]);

  useEffect(() => {
    if (me && gid && consentGiven) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ReactGA.gtag("config", gid, { user_id: me?.id });
      // eslint-disable-next-line @typescript-eslint/naming-convention
      ReactGA.gtag("set", "user_properties", { user_type: me?.__typename });
    }
  }, [consentGiven, gid, me]);

  useEffect(() => {
    if (gid) {
      ReactGA.gtag("consent", "update", {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        analytics_storage: consentGiven ? "granted" : "denied"
      });
    }
  }, [consentGiven, gid]);

  //////////////////////////////////////////////////////////////////////////////////////
  // DATADOG
  //////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (cookieConsentStatus.acceptsAnalyticsCookies) {
      datadogLogs.setTrackingConsent("granted");
    } else {
      datadogLogs.setTrackingConsent("not-granted");
    }
  }, [cookieConsentStatus.acceptsAnalyticsCookies]);

  return <>{children}</>;
};

export default AnalyticsProvider;
