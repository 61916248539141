import { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import BodyCopy from "@components/v1/typography/BodyCopy";
import ButtonLink from "@components/v1/typography/ButtonLink";
import Icon from "@components/v2/icons/Icon";
import add from "@icons/solid/circle-plus.svg";
import prescription from "@icons/solid/prescription.svg";
import balanceScaleRight from "@icons/solid/scale-unbalanced-flip.svg";
import userDoctor from "@icons/solid/user-doctor.svg";

type Props = {
  children: ReactNode;
  handleAdd: () => void;
  kind: "legal" | "provider" | "medical";
};

const Tracker = ({ children, handleAdd, kind }: Props) => {
  const icon: IconSvg = kind === "legal" ? balanceScaleRight : kind === "medical" ? prescription : userDoctor;

  return (
    <div className="margin-20-bottom">
      <ButtonLink expand onClick={handleAdd} stylized={false}>
        <div className="flex items-center gap-lg">
          <Icon color="secondary" icon={icon} />
          <BodyCopy bold className="flex-grow">
            <FormattedMessage id={`components.CarePlan.sections.trackers.${kind}.title`} />
          </BodyCopy>
          <Icon color="primary" icon={add} />
        </div>
      </ButtonLink>
      <hr className="ion-hide-lg-down" color="gray-300" />
      <div className="ion-hide-lg-up margin-20-bottom" />
      {children}
    </div>
  );
};

export default Tracker;
