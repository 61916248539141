import { App as CapacitorApp } from "@capacitor/app";
import { IonApp, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { ErrorBoundary, withProfiler } from "@sentry/react";
import { createBrowserHistory } from "history";
import { FC, lazy, Suspense, useEffect } from "react";
import { Redirect, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "@theme/variables.css";
import "@theme/colors.css";
import "@theme/fonts.css";
import "@theme/styling.css";
import "@theme/print.css";
import "@theme/transitions.css";
import "@theme/utilities.css";

import AnalyticsProvider from "@components/v1/AnalyticsProvider";
import ApiProvider from "@components/v1/ApiProvider";
import styles from "@components/v1/App.module.css";
import AppUpdatedNotification from "@components/v1/AppUpdatedNotification";
import AppUpdatedProvider from "@components/v1/AppUpdatedProvider";
import AppUrlListener from "@components/v1/AppUrlListener";
import FakeGoogleAuthPage from "@components/v1/auth/FakeGoogleAuthPage";
import BootupProvider from "@components/v1/BootupProvider";
import CookieConsentProvider from "@components/v1/CookieConsentProvider";
import ErrorFallback from "@components/v1/ErrorFallback";
import EventTracker from "@components/v1/EventTracker";
import GestureHandler from "@components/v1/GestureHandler";
import IdleHandler from "@components/v1/IdleHandler";
import IntlProvider from "@components/v1/IntlProvider";
import NotInterestedPage from "@components/v1/NotInterestedPage";
import PushNotificationSetup from "@components/v1/PushNotificationSetup";
import ReferralProgramAgreementPage from "@components/v1/ReferralProgramAgreementPage";
import ReviewRequest from "@components/v1/ReviewRequest";
import SentryRoute from "@components/v1/SentryRoute";
import TrackPageview from "@components/v1/TrackPageview";
import UtmHandler from "@components/v1/UtmHandler";
import GetCommunityMe from "@components/v2/community/GetCommunityMe";
import Icon from "@components/v2/icons/Icon";
import BannerStore from "@context/BannerStore";
import FullscreenModalStore from "@context/FullscreenModalStore";
import OpenerStore from "@context/OpenerStore";
import PageAnimationStore from "@context/PageAnimationStore";
import ScrollPositionStore from "@context/ScrollPositionStore";
import ValidationErrorStore from "@context/ValidationErrorStore";
import enTranslations from "@i18n/en";
import spinner from "@icons/solid/spinner.svg";
import { ClientAccessTypeEnum } from "@typing/Generated";
import { ActivatePageTopic } from "@typing/Models";

const AdminRouter = lazy(async () => import("@components/v1/admin/AdminRouter"));
const ClientRouter = lazy(async () => import("@components/v1/client/ClientRouter"));
const ExpertWrapperRouter = lazy(async () => import("@components/v1/expert/ExpertWrapperRouter"));
const OnboardingRouter = lazy(async () => import("@components/v1/onboarding/OnboardingRouter"));

const ActivatePage = lazy(async () => import("@components/v1/ActivatePage"));
const ActivateTopicPage = lazy(async () => import("@components/v1/ActivateTopicPage"));
const CareTeamInviteReroute = lazy(async () => import("@components/v1/auth/CareTeamInviteReroute"));
const ConfirmEmailPage = lazy(async () => import("@components/v1/auth/ConfirmEmailPage"));
const CookiePolicyPage = lazy(async () => import("@components/v1/CookiePolicyPage"));
const EmbedSignedDocumentPage = lazy(async () => import("@components/v1/EmbedSignedDocumentPage"));
const EndImpersonation = lazy(async () => import("@components/v1/admin/EndImpersonation"));
const FaqPage = lazy(async () => import("@components/v1/FaqPage"));
const GoogleOAuthCallbackPage = lazy(async () => import("@components/v1/auth/GoogleOAuthCallbackPage"));
const InviteeRegistrationPage = lazy(async () => import("@components/v1/auth/InviteeRegistrationPage"));
const LoginPage = lazy(async () => import("@components/v1/auth/LoginPage"));
const LoginSuccessPage = lazy(async () => import("@components/v1/auth/LoginSuccessPage"));
const NewPasswordPage = lazy(async () => import("@components/v1/auth/NewPasswordPage"));
const OAuthPage = lazy(async () => import("@components/v1/auth/OAuthPage"));
const OidcAuthenticationCompletePage = lazy(async () => import("@components/v1/auth/OidcAuthenticationCompletePage"));
const OidcAuthenticationStartPage = lazy(async () => import("@components/v1/auth/OidcAuthenticationStartPage"));
const OidcSignOutCompletePage = lazy(async () => import("@components/v1/auth/OidcSignOutCompletePage"));
const OldRegistrationSupportPage = lazy(async () => import("@components/v1/OldRegistrationSupportPage"));
const PageNotFoundPage = lazy(async () => import("@components/v1/PageNotFoundPage"));
const RegistrationPage = lazy(async () => import("@components/v1/auth/RegistrationPage"));
const ResendConfirmWorkEmailPage = lazy(async () => import("@components/v1/auth/ResendConfirmWorkEmailPage"));
const ResetPasswordPage = lazy(async () => import("@components/v1/auth/ResetPasswordPage"));
const ResetPasswordSuccessPage = lazy(async () => import("@components/v1/auth/ResetPasswordSuccessPage"));
const SignOut = lazy(async () => import("./SignOut"));
const TwoFactorAuthenticationPage = lazy(async () => import("@components/v1/auth/TwoFactorAuthenticationPage"));
const UnauthenticatedArticlePage = lazy(async () => import("@components/v1/UnauthenticatedArticlePage"));

setupIonicReact({ rippleEffect: false });

const App: FC = () => {
  useEffect(
    () => () => {
      CapacitorApp.removeAllListeners();
    },
    []
  );

  const history = createBrowserHistory();

  return (
    <AppUpdatedProvider>
      <ApiProvider>
        <BootupProvider>
          <GetCommunityMe />
          <IntlProvider locale="en" messages={enTranslations}>
            <ValidationErrorStore>
              <ScrollPositionStore>
                <IonReactRouter history={history}>
                  <QueryParamProvider adapter={ReactRouter5Adapter}>
                    <OpenerStore>
                      <GestureHandler>
                        <PageAnimationStore>
                          <IonApp className={styles.app}>
                            <CookieConsentProvider>
                              <FullscreenModalStore>
                                <PushNotificationSetup />
                                <IdleHandler />
                                <AppUrlListener />
                                <UtmHandler />
                                <BannerStore>
                                  <AnalyticsProvider>
                                    <EventTracker>
                                      <ReviewRequest />
                                      <ErrorBoundary fallback={ErrorFallback} showDialog>
                                        <Suspense fallback={null}>
                                          <SentryRoute path="/" render={() => <TrackPageview />} />
                                          <Switch>
                                            <SentryRoute path="/sign-out" render={() => <SignOut />} />
                                            <SentryRoute
                                              exact
                                              path="/registration/new"
                                              render={() => <OldRegistrationSupportPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/confirm-email"
                                              render={() => <ConfirmEmailPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/cookie-policy"
                                              render={() => <CookiePolicyPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/resend-work-email"
                                              render={() => <ResendConfirmWorkEmailPage />}
                                            />
                                            <SentryRoute exact path="/log-in" render={() => <LoginPage />} />
                                            <SentryRoute exact path="/login" render={() => <LoginPage />} />
                                            <SentryRoute
                                              exact
                                              path="/new-password"
                                              render={() => <NewPasswordPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/reset-password-success"
                                              render={() => <ResetPasswordSuccessPage />}
                                            />
                                            <SentryRoute exact path="/activate" render={() => <ActivatePage />} />
                                            <SentryRoute
                                              exact
                                              path="/activate/:topic"
                                              render={params => (
                                                <ActivateTopicPage
                                                  topic={params.match.params.topic as ActivatePageTopic}
                                                />
                                              )}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/reset-password"
                                              render={() => <ResetPasswordPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/auth/two-factor-authentication"
                                              render={() => <TwoFactorAuthenticationPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/auth/google-oauth-callback"
                                              render={() => <GoogleOAuthCallbackPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/auth/oidc/callback"
                                              render={() => <OidcAuthenticationCompletePage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/auth/oidc/sign-out"
                                              render={() => <OidcSignOutCompletePage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/auth/oidc/:providerName"
                                              render={params => (
                                                <OidcAuthenticationStartPage
                                                  providerName={params.match.params.providerName}
                                                />
                                              )}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/login/callback"
                                              render={() => <Icon icon={spinner} size="large" spin />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/sign-up"
                                              render={() => (
                                                <RegistrationPage
                                                  accessType={ClientAccessTypeEnum.EMPLOYER_SPONSORED}
                                                />
                                              )}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/sign-up-paid"
                                              render={() => <RegistrationPage accessType={ClientAccessTypeEnum.PAID} />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/accept-invite"
                                              render={() => <CareTeamInviteReroute />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/invite-register"
                                              render={() => <InviteeRegistrationPage />}
                                            />
                                            {/* This is the legacy share URL, needs to be maintained for existing links */}
                                            <SentryRoute
                                              exact
                                              path="/articles/:articleId/shares/:shareId"
                                              render={params => (
                                                <UnauthenticatedArticlePage shareId={params.match.params.shareId} />
                                              )}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/article-shares/:shareId"
                                              render={params => (
                                                <UnauthenticatedArticlePage shareId={params.match.params.shareId} />
                                              )}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/admin/end-impersonation"
                                              render={() => <EndImpersonation />}
                                            />

                                            <SentryRoute path="/admin" render={() => <AdminRouter />} />
                                            <SentryRoute path="/client" render={() => <ClientRouter />} />
                                            <SentryRoute path="/expert" render={() => <ExpertWrapperRouter />} />
                                            <SentryRoute
                                              path="/onboarding/journeys/:journeyId"
                                              render={params => (
                                                <OnboardingRouter journeyId={params.match.params.journeyId} />
                                              )}
                                            />
                                            <SentryRoute exact path="/oauth" render={() => <OAuthPage />} />
                                            <SentryRoute
                                              exact
                                              path="/auth/login-success"
                                              render={() => <LoginSuccessPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/auth/fake-google-auth"
                                              render={() => <FakeGoogleAuthPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/embed-signed-document"
                                              render={() => <EmbedSignedDocumentPage />}
                                            />
                                            {/*
                                            These are where the user winds up after Google auth on mobile devices. When that
                                            happens, we get a callback that will forward them to the right place in a sec,
                                            but we need to render a blank page here so they don't see page not found in
                                            the interim.
                                          */}
                                            <SentryRoute exact path="/&prompt=consent" render={() => null} />
                                            <SentryRoute
                                              exact
                                              path="/auth/userinfo.profile&authuser=0&prompt=consent"
                                              render={() => null}
                                            />
                                            <SentryRoute exact path="/" render={() => <Redirect to="/log-in" />} />
                                            <SentryRoute exact path="/faq" render={() => <FaqPage />} />
                                            <SentryRoute
                                              exact
                                              path="/referral-program-agreement"
                                              render={() => <ReferralProgramAgreementPage />}
                                            />
                                            <SentryRoute
                                              exact
                                              path="/not-interested"
                                              render={() => <NotInterestedPage />}
                                            />
                                            <SentryRoute render={() => <PageNotFoundPage />} />
                                          </Switch>
                                          <AppUpdatedNotification />
                                        </Suspense>
                                      </ErrorBoundary>
                                    </EventTracker>
                                  </AnalyticsProvider>
                                </BannerStore>
                              </FullscreenModalStore>
                            </CookieConsentProvider>
                          </IonApp>
                        </PageAnimationStore>
                      </GestureHandler>
                    </OpenerStore>
                  </QueryParamProvider>
                </IonReactRouter>
              </ScrollPositionStore>
            </ValidationErrorStore>
          </IntlProvider>
        </BootupProvider>
      </ApiProvider>
    </AppUpdatedProvider>
  );
};

const ProfiledApp = withProfiler(App);

export default ProfiledApp;
