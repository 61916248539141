import { IonItem, IonLabel } from "@ionic/react";
import { ComponentProps, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import styles from "@components/v1/trackers/TrackedItemLine.module.css";
import BodyCopy from "@components/v1/typography/BodyCopy";
import Heading2 from "@components/v1/typography/Heading2";
import IconBase from "@components/v2/icons/IconBase";

type Props = Pick<ComponentProps<typeof IonItem>, "color"> & {
  description: ReactNode;
  icon?: IconSvg;
  labelKey?: string;
};

const TrackedItemLine = ({ description, icon, labelKey, ...ionItemProps }: Props) => {
  const content = (
    <IonItem {...ionItemProps} className={styles.item} lines="none">
      {icon && (
        <IconBase
          className="margin-4-top margin-4-bottom margin-10-right"
          color="gray-200"
          icon={icon}
          size="medium"
          slot="start"
        />
      )}
      {labelKey && (
        <IonLabel className="margin-4-top margin-4-bottom margin-10-right" color="dark" slot="start">
          <Heading2 bold color="dark">
            <FormattedMessage id={labelKey} />
          </Heading2>
        </IonLabel>
      )}
      <BodyCopy color="dark" size="small">
        {description}
      </BodyCopy>
    </IonItem>
  );

  return content;
};

export default TrackedItemLine;
