import { useIonToast } from "@ionic/react";
import { ReactElement, ReactNode } from "react";

import { errorContext } from "@context/Contexts";
import questionCircle from "@icons/solid/circle-question.svg";
import xmark from "@icons/solid/xmark.svg";

const ValidationErrorStore = ({ children }: { children: ReactNode }): ReactElement => {
  const [present, dismiss] = useIonToast();

  const addError = (error: string) => {
    present({
      buttons: [
        {
          handler: () => {
            window.open("mailto: support@withgrayce.com");
          },
          icon: questionCircle
        },
        { handler: () => dismiss(), icon: xmark }
      ],
      color: "danger",
      cssClass: "toast",
      duration: 5000,
      message: error,
      position: "top"
    });
  };

  return (
    <errorContext.Provider
      value={{
        addError
      }}
    >
      {children}
    </errorContext.Provider>
  );
};

export default ValidationErrorStore;
