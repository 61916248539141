import { TypePolicies } from "@apollo/client";

import { parseDateScalar } from "@utils/scalarUtils";

const fieldPolicies: TypePolicies = {
  Activity: {
    fields: {
      activeAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Admin: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Agreement: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      revokedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AiChatMessage: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AiChatThread: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AppEvent: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Article: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) },
      webinarStartTime: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ArticleSearchResult: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) },
      webinarStartTime: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ArticleShare: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AssessmentStep: {
    fields: {
      completedAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Attachment: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Automation: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AutomationActionBlock: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AutomationApplication: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AutomationBlockApplication: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      timingBeganAt: { read: (value: string) => parseDateScalar(value) },
      timingExecuteAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AutomationCondition: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AutomationConditionBlock: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AutomationConditionGroup: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AutomationTimingBlock: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  AutomationTriggerBlock: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Banner: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      dismissedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CarePlanActionItem: {
    fields: {
      completedAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      ownerViewedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CarePlanTaskTemplate: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CarePlanTaskTemplateSearchResult: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfile: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      dateOfBirth: { read: (value: string) => parseDateScalar(value) },
      dateOfDeath: { read: (value: string) => parseDateScalar(value) },
      exactAgeEnteredAt: { read: (value: string) => parseDateScalar(value) },
      nextConsult: { read: (value: string) => parseDateScalar(value) },
      pregnancyDueDate: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileCaregiverTypes: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileChange: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      date: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileCurrentCareTransitions: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileEmotionalConcerns: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileFieldRule: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileHealthInsuranceTypes: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileInsuranceTypes: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileReligion: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareProfileResidenceType: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CareTeamInvite: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      lastSentAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Chat: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ChatParticipant: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Client: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      dateOfBirth: { read: (value: string) => parseDateScalar(value) },
      firstEligibleAt: { read: (value: string) => parseDateScalar(value) },
      lastOtpAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ClientAttendees: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ClientDeletion: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ClientRole: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityBadge: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityBlob: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityGroup: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityGroupMembership: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityMediaFile: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityPost: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      publishedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityPostImage: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityPostVideo: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityReaction: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CommunityUser: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      lastActiveAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Consult: {
    fields: {
      canceledAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      endTime: { read: (value: string) => parseDateScalar(value) },
      startTime: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ConsultAvailability: {
    fields: {
      startTime: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ConsultAvailabilityDay: {
    fields: {
      date: { read: (value: string) => parseDateScalar(value) },
      startTime: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ConsultInvite: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ConsultReport: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      publishedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ConsultReportDiscussionItem: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ContentCondition: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  CoworkerReferral: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  DateTimeField: {
    fields: {
      dateTime: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Diagnosis: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      date: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Drug: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  DrugSearchResult: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EligibilityFile: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      dryRunAt: { read: (value: string) => parseDateScalar(value) },
      executedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Email: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) },
      verifiedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EmailMessage: {
    fields: {
      bouncedAt: { read: (value: string) => parseDateScalar(value) },
      complainedAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      deliveredAt: { read: (value: string) => parseDateScalar(value) },
      openedAt: { read: (value: string) => parseDateScalar(value) },
      scheduledFor: { read: (value: string) => parseDateScalar(value) },
      sentAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Employee: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      dateOfBirth: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EmployeeCountByCountry: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Employer: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EmployerBenefit: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EmployerBenefitSearchResult: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EmployerContract: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      endDate: { read: (value: string) => parseDateScalar(value) },
      startDate: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EmployerContractModification: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      startDate: { read: (value: string) => parseDateScalar(value) },
      startTime: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EmployerIndustry: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  EmployerQbrDailyRegistrations: {
    fields: {
      date: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Expert: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ExpertRole: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      endTime: { read: (value: string) => parseDateScalar(value) },
      openedAt: { read: (value: string) => parseDateScalar(value) },
      startTime: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  FaxAttempt: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  FileResource: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Folder: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Goal: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      ownerViewedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  GoalItem: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  GoalTemplate: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  GoalTemplateSearchResult: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Hotline: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  InitialConsultReport: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      publishedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  InternalNote: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  InternalNoteTemplate: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Journey: {
    fields: {
      carePlanDueDate: { read: (value: string) => parseDateScalar(value) },
      carePlanPublishedAt: { read: (value: string) => parseDateScalar(value) },
      carePlanUpdatedAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      lastClientContact: { read: (value: string) => parseDateScalar(value) },
      lastExpertContact: { read: (value: string) => parseDateScalar(value) },
      losesExpertAccessAt: { read: (value: string) => parseDateScalar(value) },
      ownerViewedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  JourneySearchResult: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      lastActivityAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  JourneySummary: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  JourneyTag: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  JourneyTagging: {
    fields: {
      addedAt: { read: (value: string) => parseDateScalar(value) },
      archivedAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  JourneyTaggingsSummary: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  LegalDocument: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updateDate: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  LifecycleEvent: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Link: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Location: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  MedicalCondition: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  MedicalConditionSearchResult: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Medication: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  MedicationFormat: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Message: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  MessageTemplate: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  MessageTemplateCategory: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Notification: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      readAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  OidcProvider: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  OutOfOfficePeriod: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      endTime: { read: (value: string) => parseDateScalar(value) },
      startTime: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  OutOfOfficePeriodConflicts: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  OutOfOfficeSummary: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  PackageGrant: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      eligibilityDeterminedAt: { read: (value: string) => parseDateScalar(value) },
      expiresAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  PackagePurchaseOption: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  PaymentIntent: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  PeerSupportRequest: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  PhoneNumber: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) },
      verifiedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Pin: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Provider: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Purchase: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  PushNotificationToken: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Query: {
    fields: {
      grayceHolidays: { read: (value: string) => parseDateScalar(value) ?? [] }
    }
  },
  Recommendation: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  RefreshPeriod: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      endDate: { read: (value: string) => parseDateScalar(value) },
      startDate: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Resource: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      ownerViewedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ResourceFeature: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ResourceFeatureTemplate: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  ResourceFeatureValue: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  SignedDocument: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      revokedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  SurveyResponse: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      submittedAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Task: {
    fields: {
      completedAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      dueDate: { read: (value: string) => parseDateScalar(value) },
      dueTime: { read: (value: string) => parseDateScalar(value) },
      expectedStartDate: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  TaskAuditLog: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      timestamp: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  TimeLog: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      date: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  TimeLogActivityKind: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  TimeLogActivityKindGroupMinutes: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  TimeLogSupportKind: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  WebinarRegistration: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  Worksheet: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  WorksheetItem: {
    fields: {
      completedAt: { read: (value: string) => parseDateScalar(value) },
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  WorksheetTemplate: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  WorksheetTemplateItem: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  },
  WorksheetTemplateSearchResult: {
    fields: {
      createdAt: { read: (value: string) => parseDateScalar(value) },
      updatedAt: { read: (value: string) => parseDateScalar(value) }
    }
  }
};

export default fieldPolicies;
